var randomString = function randomString(len) {
  var myCharSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var randomString = "";

  for (var i = 0; i < len; i++) {
    var randomPos = Math.floor(Math.random() * myCharSet.length);
    randomString += myCharSet.substring(randomPos, randomPos + 1);
  }

  return randomString;
};

export default randomString;