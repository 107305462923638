import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
export { configureStore } from "./configureStore";
import { useMemo } from "react";
import { shallowEqual, useDispatch as originalUseDispatch, useSelector as originalUseSelector } from "react-redux";
import { bindActionCreators } from "redux";
export var useSelector = function useSelector(selector, equalityFn) {
  return originalUseSelector(selector, equalityFn);
};
export var useShallowSelector = function useShallowSelector(selector) {
  return originalUseSelector(selector, shallowEqual);
};
export var useDispatch = function useDispatch() {
  return originalUseDispatch();
};
export function useActions(actions, deps) {
  var dispatch = useDispatch();
  return useMemo(function () {
    return bindActionCreators(actions, dispatch);
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  deps ? [dispatch].concat(_toConsumableArray(deps)) : [dispatch]);
}