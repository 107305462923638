export var getAlternateUrl = function getAlternateUrl(urlString) {
  var alternate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "mobile";

  if (urlString.indexOf("devcat.ch") >= 0) {
    switch (alternate) {
      case "au":
        return urlString.replace(/canzweb|cotdmob|canzmob/i, "cotdweb");

      case "mobile":
        {
          if (urlString.indexOf("canzweb") >= 0) {
            return urlString.replace(/canzweb/i, "canzmob");
          }

          return urlString.replace(/cotdweb/i, "cotdmob");
        }

      case "nz":
        return urlString.replace(/cotdweb|cotdmob|canzmob/i, "canzweb");

      default:
        return urlString;
    }
  } else {
    if (alternate !== "mobile") {
      urlString = urlString.replace(/m\.catch/i, "www.catch");
    }

    switch (alternate) {
      case "au":
        return urlString.replace(/catch\.co\.nz/i, "catch.com.au");

      case "mobile":
        return urlString.replace(/www\.catch/i, "m.catch");

      case "nz":
        return urlString.replace(/catch\.com\.au/i, "catch.co.nz");

      default:
        return urlString;
    }
  }

  return origin;
};