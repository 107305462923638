export function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === "?" ? queryString.substr(1) : queryString).split("&");
  pairs.forEach(function (entry) {
    var pair = entry.split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  });
  return query;
}
export function getPathname() {
  if (typeof location === "undefined") {
    return undefined;
  }

  return location.pathname;
}
export function isSearchPage() {
  return getPathname() === "/search";
}