import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";

/**
 * Serialize a query object and prepend any additional parameters.
 * Will handle encoding of the query object.
 */
export function serializeQueryString( // Query object to be serialzed
queryObject) {
  var serializedQuery = Object.entries(queryObject).map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    if (value === undefined) {
      return undefined;
    }

    var encodedValue = Array.isArray(value) ? encodeURIComponent(value.filter(function (v) {
      return v !== undefined;
    }).join(";")) : encodeURIComponent(value);
    return "".concat(encodeURIComponent(key), "=").concat(encodedValue);
  }).filter(Boolean).join("&");
  return serializedQuery;
}