export function cleanHostName() {
  var host = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var capitalize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var cleaned = host.replace("www.", "").replace(/:.*$/, "");

  if (!capitalize) {
    return cleaned;
  }

  return cleaned.length > 0 ? "".concat(cleaned[0].toUpperCase()).concat(cleaned.slice(1)) : cleaned;
}