import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["attribute", "brand", "clubCatchExclusive", "clubCatchPrice", "discountPercent", "id", "imageLocation", "offerId", "priceDisplayType", "promotionDescription", "quantityForSale", "retailPrice", "savingsLabel", "selectedOfferDiscountEndDate", "sellerId", "sellPrice", "showClubCatchPrice", "showFromLabel", "showPromotionPrice", "showPromotionTimer", "showRetailPrice", "showSavingsLabel", "showShortDatedBadge", "showWasLabel", "urgentMessage", "wasPrice", "clearance", "clearancePriceLabel", "selectedOfferVariantId"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import Url from "url-parse";
import { getEnvConfig } from "@catchoftheday/env-config";
import { dollarsToCents, formatPrice } from "@catchoftheday/utilities";

var resultToProductItem = function resultToProductItem(result, source) {
  var _Url$query, _brand$url;

  var _ref = result,
      product = _ref.product,
      trackEventToken = _ref.trackEventToken; // Override sourceType if st query param is present in the query string

  var sourceTypeOverride = true ? (_Url$query = Url(window.location.href, true).query) === null || _Url$query === void 0 ? void 0 : _Url$query.st : null;
  var sourceId = product.sourceId;
  var sourceType = sourceTypeOverride || product.sourceType || source.type;
  var queryObject = {
    sid: sourceId,
    st: sourceType,
    srtrev: trackEventToken && "".concat(trackEventToken, ".click")
  };
  Object.keys(queryObject).forEach(function (key) {
    return queryObject[key] === undefined && delete queryObject[key];
  });
  var urlObject = new Url(product.url, true);
  urlObject.set("query", queryObject); // Trim `origin` from url, so there's no mismatch between client and server
  // side rendered components

  var url = urlObject.href.replace(urlObject.origin, "");

  var attribute = product.attribute,
      brand = product.brand,
      clubCatchExclusive = product.clubCatchExclusive,
      clubCatchPrice = product.clubCatchPrice,
      discountPercent = product.discountPercent,
      id = product.id,
      imageLocation = product.imageLocation,
      offerId = product.offerId,
      priceDisplayType = product.priceDisplayType,
      promotionDescription = product.promotionDescription,
      quantityForSale = product.quantityForSale,
      retailPrice = product.retailPrice,
      savingsLabel = product.savingsLabel,
      selectedOfferDiscountEndDate = product.selectedOfferDiscountEndDate,
      sellerId = product.sellerId,
      sellPrice = product.sellPrice,
      showClubCatchPrice = product.showClubCatchPrice,
      showFromLabel = product.showFromLabel,
      showPromotionPrice = product.showPromotionPrice,
      showPromotionTimer = product.showPromotionTimer,
      showRetailPrice = product.showRetailPrice,
      showSavingsLabel = product.showSavingsLabel,
      showShortDatedBadge = product.showShortDatedBadge,
      showWasLabel = product.showWasLabel,
      urgentMessage = product.urgentMessage,
      wasPrice = product.wasPrice,
      clearance = product.clearance,
      clearancePriceLabel = product.clearancePriceLabel,
      selectedOfferVariantId = product.selectedOfferVariantId,
      item = _objectWithoutProperties(product, _excluded);

  var badges = [].concat(_toConsumableArray(urgentMessage ? ["almost-gone"] : []), _toConsumableArray(showShortDatedBadge ? ["short-dated"] : []), _toConsumableArray(quantityForSale <= 0 ? ["sold-out"] : []), _toConsumableArray(clubCatchExclusive ? ["onepass-exclusive"] : []), _toConsumableArray(clearance ? ["clearance"] : []));

  function setPriceTopLabel() {
    if (clearancePriceLabel) {
      return clearancePriceLabel;
    }

    if (showFromLabel && !showPromotionPrice) {
      return "From";
    }

    if (!showPromotionPrice && priceDisplayType) {
      return "".concat(priceDisplayType, " ").concat(formatPrice(dollarsToCents(retailPrice), false));
    }

    return null;
  }

  var productItem = _objectSpread(_objectSpread({}, item), {}, {
    attribute: attribute && {
      id: "".concat(attribute.id),
      isSize: attribute.isSize || false,
      name: attribute.name || ""
    },
    brand: brand && {
      name: brand.name,
      slug: ((_brand$url = brand.url) === null || _brand$url === void 0 ? void 0 : _brand$url.replace("/brand/", "")) || ""
    },
    imageLocation: "".concat(getEnvConfig().COTD_WEBSITE_CDN_URL, "/").concat(imageLocation),
    id: "".concat(id),
    offerId: "".concat(offerId),
    sellerId: "".concat(sellerId),
    sourceId: "".concat(sourceId),
    variantId: "".concat(selectedOfferVariantId || ""),
    priceTag: {
      priceDiscount: showPromotionPrice ? {
        label: priceDisplayType,
        percentageLabel: showPromotionTimer ? null : promotionDescription,
        endDate: showPromotionTimer && selectedOfferDiscountEndDate ? selectedOfferDiscountEndDate : null
      } : null,
      topLabel: setPriceTopLabel(),
      priceLabel: {
        wasPrice: showPromotionPrice && wasPrice ? {
          amount: dollarsToCents(wasPrice)
        } : null,
        price: {
          amount: dollarsToCents(sellPrice)
        },
        saving: showSavingsLabel ? {
          label: "Save",
          savingAmount: {
            amount: dollarsToCents(savingsLabel)
          }
        } : null
      },
      clubSellPrice: showClubCatchPrice ? {
        amount: dollarsToCents(clubCatchPrice)
      } : null,
      clubExclusive: clubCatchExclusive,
      enabled: true,
      retailPriceDisclaimer: null
    },
    badges: badges
  });

  return _objectSpread(_objectSpread({}, productItem), {}, {
    url: url,
    sourceType: sourceType ? parseInt("".concat(sourceType), 10) : undefined,
    trackEventToken: trackEventToken
  });
};

export default resultToProductItem;