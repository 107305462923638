import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { traverseFindListFacetItems } from "./item-traversal";

function carouselFacetApplied(facet) {
  return facet.values.filter(function (item) {
    return item.selected;
  });
}

function listFacetApplied(facet) {
  return facet.values.filter(function (item) {
    return item.selected;
  }).map(function (item) {
    return _objectSpread(_objectSpread({}, item), {}, {
      facetName: facet.id,
      facetType: facet.type
    });
  });
}

function nestedFacetApplied(facet) {
  var applied = traverseFindListFacetItems(facet.values, function (item) {
    return item.selected;
  });

  if (applied) {
    return _objectSpread(_objectSpread({}, applied), {}, {
      facetName: facet.id,
      facetType: facet.type
    });
  }

  return null;
}

function nestedSingleFacetApplied(facet) {
  return traverseFindListFacetItems(facet.values, function (item) {
    return item.selected;
  });
}

function nestedSingleFacetAllApplied(facet) {
  return facet.values.map(function (item) {
    var _item$values;

    return (_item$values = item.values) === null || _item$values === void 0 ? void 0 : _item$values.filter(function (item) {
      return item.selected;
    }).map(function (item) {
      return _objectSpread(_objectSpread({}, item), {}, {
        facetName: facet.id,
        facetType: facet.type
      });
    });
  });
}

function rangeFacetApplied(facet) {
  if (typeof facet.selectedMax === "number" || typeof facet.selectedMin === "number") {
    return facet;
  }
}

function keyedListFacetApplied(facet) {
  return Object.keys(facet.values).map(function (key) {
    var selected = facet.values[key].filter(function (item) {
      return item.selected;
    });

    if (selected.length > 0) {
      return selected.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          key: key,
          facetName: facet.id,
          facetType: facet.type
        });
      });
    }
  }).filter(Boolean);
}

export function facetApplied(facet) {
  switch (facet.type) {
    case "carousel":
      {
        return carouselFacetApplied(facet).length > 0;
      }

    case "keyed_list":
      {
        return keyedListFacetApplied(facet).length > 0;
      }

    case "list":
      {
        return listFacetApplied(facet).length > 0;
      }

    case "nested":
      {
        return !!nestedFacetApplied(facet);
      }

    case "nested_single":
      {
        return !!nestedSingleFacetApplied(facet);
      }

    case "range":
      {
        return !!rangeFacetApplied(facet);
      }
  }
}

function keyedListFacetAppliedTotal(facet) {
  return Object.values(facet.values).reduce(function (total, items) {
    return total + items.filter(function (item) {
      return item.selected;
    }).length;
  }, 0);
}

function listFacetAppliedTotal(facet) {
  return facet.values.filter(function (item) {
    return item.selected;
  }).length;
}

function nestedSingleFacetAppliedTotal(facet) {
  return facet.values.reduce(function (total, item) {
    if (item.values) {
      return total + item.values.filter(function (item) {
        return item.selected;
      }).length;
    }

    return total;
  }, 0);
}

function rangeFacetAppliedTotal(facet) {
  return rangeFacetApplied(facet) ? 1 : 0;
}

export function facetAppliedCount(facet) {
  switch (facet.type) {
    case "keyed_list":
      {
        return keyedListFacetAppliedTotal(facet);
      }

    case "list":
      {
        return listFacetAppliedTotal(facet);
      }

    case "nested":
      {
        return listFacetAppliedTotal(facet);
      }

    case "nested_single":
      {
        return nestedSingleFacetAppliedTotal(facet);
      }

    case "range":
      {
        return rangeFacetAppliedTotal(facet);
      }

    default:
      return 0;
  }
}
export function allAppliedFacets(facets) {
  return facets.reduce(function (appliedFacets, facet) {
    switch (facet.type) {
      case "keyed_list":
        {
          var appliedKeyedListFacets = keyedListFacetApplied(facet);
          appliedKeyedListFacets.map(function (items) {
            items === null || items === void 0 ? void 0 : items.map(function (_ref) {
              var id = _ref.id,
                  name = _ref.name,
                  key = _ref.key,
                  facetName = _ref.facetName,
                  facetType = _ref.facetType;
              appliedFacets.push({
                id: id,
                name: "".concat(key, " ").concat(name),
                facetName: facetName,
                facetType: facetType
              });
            });
          });
          break;
        }

      case "list":
        {
          var appliedListFacets = listFacetApplied(facet);
          appliedListFacets.map(function (_ref2) {
            var id = _ref2.id,
                name = _ref2.name,
                facetName = _ref2.facetName,
                facetType = _ref2.facetType;
            appliedFacets.push({
              id: id,
              name: name,
              facetName: facetName,
              facetType: facetType
            });
          });
          break;
        }

      case "nested":
        {
          var appliedNestedFecets = nestedFacetApplied(facet);

          if (appliedNestedFecets) {
            var id = appliedNestedFecets.id,
                name = appliedNestedFecets.name,
                facetName = appliedNestedFecets.facetName,
                facetType = appliedNestedFecets.facetType;

            if (id && name) {
              appliedFacets.push({
                id: id,
                name: name,
                facetName: facetName,
                facetType: facetType
              });
            }
          }

          break;
        }

      case "nested_single":
        {
          var appliedNestedSingleFacets = nestedSingleFacetAllApplied(facet);
          appliedNestedSingleFacets.map(function (items) {
            if (items !== null && items !== void 0 && items.length) {
              items.map(function (_ref3) {
                var id = _ref3.id,
                    name = _ref3.name,
                    facetName = _ref3.facetName,
                    facetType = _ref3.facetType;
                appliedFacets.push({
                  id: id,
                  name: name,
                  facetName: facetName,
                  facetType: facetType
                });
              });
            }
          });
          break;
        }

      case "range":
        {
          var appliedRangeFacets = rangeFacetApplied(facet);

          if (appliedRangeFacets) {
            var prefix = "";
            var suffix = "";
            var _id = appliedRangeFacets.id,
                min = appliedRangeFacets.min,
                max = appliedRangeFacets.max,
                selectedMin = appliedRangeFacets.selectedMin,
                selectedMax = appliedRangeFacets.selectedMax,
                settings = appliedRangeFacets.settings; // If the range is changed back to its original value, don't show it as applied

            if (selectedMax === max && !selectedMin || selectedMin === min && !selectedMax || selectedMin === min && selectedMax === max) {
              break;
            } // If this is a price range append a $ otherwise prepend the unit


            if (_id === "price_range") {
              prefix = (settings === null || settings === void 0 ? void 0 : settings.unit) || "$";
            } else {
              suffix = settings ? " ".concat(settings.unit) : "";
            }

            appliedFacets.push({
              id: _id,
              name: "".concat(prefix).concat(selectedMin || min).concat(suffix, " to ").concat(prefix).concat(selectedMax || max).concat(suffix),
              facetName: _id,
              facetType: facet.type
            });
          }

          break;
        }
    }

    return appliedFacets.filter(Boolean).flat();
  }, []);
}