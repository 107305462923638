import { combineReducers } from "@reduxjs/toolkit";
import { initialState as initialAdultModalState, reducer as adultModalReducer } from "./adultModal/reducer";
import { initialState as initialCustomerState, reducer as customerReducer } from "./customer/reducer";
import { initialState as initialSearchState, reducer as searchReducer } from "./search/reducer";
import { initialState as initialSearchConfigState, reducer as searchConfigReducer } from "./searchConfig/reducer";
import { initialState as initialSeoState, reducer as seoReducer } from "./seo/reducer";
import { initialState as initialSiteContextState, reducer as siteContextReducer } from "./siteContext/reducer";
export var initialState = {
  search: initialSearchState,
  seo: initialSeoState,
  adultModal: initialAdultModalState,
  searchConfig: initialSearchConfigState,
  customer: initialCustomerState,
  siteContext: initialSiteContextState
};
export var reducer = combineReducers({
  search: searchReducer,
  adultModal: adultModalReducer,
  searchConfig: searchConfigReducer,
  customer: customerReducer,
  siteContext: siteContextReducer,
  seo: seoReducer
});