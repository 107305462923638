import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { createReducer } from "@reduxjs/toolkit";
import { searchFetch } from "@search-app/store/search/actions";
export var initialState = {};
export var reducer = createReducer(initialState, function (builder) {
  return builder.addCase(searchFetch.fulfilled, function (state, action) {
    var _action$payload$seo, _action$payload$seo2, _action$payload$seo3, _action$payload$seo4, _action$payload$seo5;

    var newState = _objectSpread(_objectSpread({}, state), {}, {
      footerHtmlContent: (_action$payload$seo = action.payload.seo) === null || _action$payload$seo === void 0 ? void 0 : _action$payload$seo.htmlDescription,
      alternate: (_action$payload$seo2 = action.payload.seo) === null || _action$payload$seo2 === void 0 ? void 0 : _action$payload$seo2.alternate,
      canonical: (_action$payload$seo3 = action.payload.seo) === null || _action$payload$seo3 === void 0 ? void 0 : _action$payload$seo3.canonical,
      metaTitle: (_action$payload$seo4 = action.payload.seo) === null || _action$payload$seo4 === void 0 ? void 0 : _action$payload$seo4.metaTitle,
      metaDescription: (_action$payload$seo5 = action.payload.seo) === null || _action$payload$seo5 === void 0 ? void 0 : _action$payload$seo5.metaDescription
    });

    return newState;
  });
});