import { trackSelfDescribingEvent } from "@snowplow/browser-tracker";
export function emitBannerClick(_ref) {
  var id = _ref.id,
      name = _ref.name,
      targetUrl = _ref.targetUrl,
      position = _ref.position,
      _ref$sourceType = _ref.sourceType,
      sourceType = _ref$sourceType === void 0 ? null : _ref$sourceType;
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:au.com.catch/banner_click/jsonschema/1-0-2",
      data: {
        schedule_entry_id: id,
        position: position,
        target_url: targetUrl,
        banner_image_name: name,
        source_type: sourceType
      }
    }
  });
}