import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { createReducer } from "@reduxjs/toolkit";
import { cookies } from "@catchoftheday/utilities";
import { searchFetch } from "@search-app/store/search/actions";
import { closeAdultModal } from "../actions";

var cookieGet = function cookieGet(key, defaultValue) {
  try {
    return JSON.parse(cookies.get("over18"));
  } catch (e) {
    return defaultValue;
  }
};

export var initialState = {
  open: false,
  isAdult: cookieGet("over18", false)
};
export var reducer = createReducer(initialState, function (builder) {
  return builder.addCase(searchFetch.fulfilled, function (state, action) {
    var context = action.payload.contexts;
    var containsAdult = context.some(function (obj) {
      return obj.data.adult_search;
    });
    var open = containsAdult && !state.isAdult;
    return _objectSpread(_objectSpread({}, state), {}, {
      open: open
    });
  }).addCase(closeAdultModal, function (state) {
    cookies.set("over18", "true", {
      expires: 30
    });
    return _objectSpread(_objectSpread({}, state), {}, {
      open: false,
      isAdult: true
    });
  });
});