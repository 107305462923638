/**
 * Facet Types
 *
 * @format
 */
export var SEARCH_FACET_TYPE_KEYED_LIST = "keyed_list";
export var SEARCH_FACET_TYPE_LIST = "list";
export var SEARCH_FACET_TYPE_NESTED = "nested";
export var SEARCH_FACET_TYPE_NESTED_SINGLE = "nested_single";
export var SEARCH_FACET_TYPE_RANGE = "range"; // const SEARCH_FACET_TYPE_SINGLE = "single";

/** Any facet with the type `carousel` will be displayed on the facet carousel
 * and be excluded from the sidebar
 */

export var SEARCH_FACET_TYPE_CAROUSEL = "carousel";
/**
 * Facet Display Types
 */

export var SEARCH_FACET_DISPLAY_TYPE_RATING = "rating";
/**
 * Facet Display IDs
 */

export var SEARCH_FACET_DISPLAY_ID_RATING = "rating_categories";
/**
 * Facet List filtered display amount
 */

export var SEARCH_FACET_FILTERED_DISPLAY_AMOUNT = 9;
/**
 * Facet List search box display threshold
 */

export var SEARCH_FACET_SEARCHBOX_THRESHOLD = 40;
/**
 * Facet List nested display amount
 */

export var SEARCH_FACET_NESTED_DISPLAY_AMOUNT = 4;
/**
 * Facet List 2cols threshold length
 */

export var SEARCH_FACET_2COLS_THRESHOLD_LENGTH = 15;
/**
 * Number of items to show before hiding behind "show more" button
 */

export var SEARCH_FACET_SHOWMORE_LIMIT = 5;
/**
 * ID to match the onepass delivery facet
 */

export var SEARCH_FACET_ONEPASS_DELIVERY_ID = "onepass";