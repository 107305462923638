import React from "react";
import { biennaleTags, montserratTags } from "./fonts-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Fonts = function Fonts(_ref) {
  var _ref$fonts = _ref.fonts,
      fonts = _ref$fonts === void 0 ? "biennale" : _ref$fonts;
  return ___EmotionJSX(React.Fragment, null, getFontTags({
    fonts: fonts
  }));
};
Fonts.displayName = "Fonts";
export var getFontTags = function getFontTags() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref2$fonts = _ref2.fonts,
      fonts = _ref2$fonts === void 0 ? "biennale" : _ref2$fonts;

  var fontList = Array.isArray(fonts) ? fonts : [fonts];
  return fontList.map(function (font) {
    switch (font) {
      case "biennale":
        {
          return biennaleTags;
        }

      case "montserrat":
        {
          return montserratTags;
        }

      default:
        {
          return null;
        }
    }
  });
};
export default Fonts;