/**
 * Returns a function that will retry the underlying function a number of times if it fails.
 */
export function retry(fn) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$tries = _ref.tries,
      tries = _ref$tries === void 0 ? [1000, 2000, 4000] : _ref$tries;

  if (tries.length === 0) {
    return fn;
  }

  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return fn.apply(void 0, args)["catch"](function () {
      return new Promise(function (res) {
        return setTimeout(function () {
          res(retry(fn, {
            tries: tries.slice(1)
          }).apply(void 0, args));
        }, tries[0]);
      });
    });
  };
}