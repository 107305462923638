export var getCanonicalUrl = function getCanonicalUrl(urlString) {
  if (urlString.indexOf("devcat.ch") >= 0) {
    if (urlString.indexOf("canzmob") >= 0) {
      return urlString.replace(/canzmob/i, "canzweb");
    } else if (urlString.indexOf("cotdmob") >= 0) {
      return urlString.replace(/cotdmob/i, "cotdweb");
    }

    return urlString;
  } else {
    return urlString.replace(/m\.catch/i, "www.catch");
  }
};