import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["Component", "pageProps"];
import React from "react";
import { Provider } from "react-redux";
import { ErrorBoundary } from "@catchoftheday/cg-components";
import { nextAppHOC } from "@catchoftheday/common-vendors";
import { getEnvConfig, withEnvConfig } from "@catchoftheday/env-config";
import { ThemeProvider } from "@catchoftheday/theme";
import { SEARCH_APOLLO_CACHE_CONFIG } from "../config/apolloCacheConfig";
import { configureStore } from "../store";
import { jsx as ___EmotionJSX } from "@emotion/react";

function SearchAppInner(_ref) {
  var Component = _ref.Component,
      pageProps = _ref.pageProps,
      rest = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(ThemeProvider, null, ___EmotionJSX(Component, _extends({}, pageProps, rest)));
}

var SearchApp = function SearchApp(props) {
  var store = React.useMemo(function () {
    return configureStore();
  }, []);
  return ___EmotionJSX(ErrorBoundary, null, ___EmotionJSX(Provider, {
    store: store
  }, ___EmotionJSX(SearchAppInner, props)));
};

export default withEnvConfig(nextAppHOC({
  graphqlEndpoint: function graphqlEndpoint() {
    return getEnvConfig().GRAPHQL_ENDPOINT;
  },
  apolloCacheConfig: SEARCH_APOLLO_CACHE_CONFIG,
  fonts: ["biennale", "montserrat"],
  seo: {
    ssr: false,
    alternateTags: {
      includeQueryParams: function includeQueryParams(_ref2) {
        var url = _ref2.url;
        return url.pathname.startsWith("/search");
      }
    },
    canonicalTag: {
      queryParams: {
        query: true,
        page: true
      }
    }
  }
})(SearchApp));