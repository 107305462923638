export var tacticalCategoryPageRecords = {
  healthBeauty: {
    pathPattern: /^\/category\/health-beauty\/browse\/?(\?.*)?$/,
    name: "Health & Beauty",
    subtitle: "Look great for less with makeup, fragrance, skincare & more.",
    titleImageUrl: "https://s.catch.com.au/assets/0001/1053/65deac8f2b807615654679.webp",
    themeColor: "#F59BE1",
    shopAllHref: "/category/health-beauty",
    subcategories: [{
      id: "makeup",
      title: "Makeup",
      href: "/category/health-beauty/make-up",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/65689437e88e3972607279.jpg"
    }, {
      id: "bath-body",
      title: "Bath & Body",
      href: "/category/health-beauty/personal-care",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/65689402d13d7623180011.jpg"
    }, {
      id: "manicure-pedicure",
      title: "Manicure & Pedicure",
      href: "/category/health-beauty/make-up-nails",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/656894485fd50779041666.jpg"
    }, {
      id: "skincare",
      title: "Skincare",
      href: "/category/health-beauty/skincare",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/656894678c0e1196013792.jpg"
    }, {
      id: "haircare",
      title: "Haircare",
      href: "/category/health-beauty/haircare",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/65689426b9401704441722.jpg"
    }, {
      id: "dental-oral-care",
      title: "Dental & Oral Care",
      href: "/category/health-beauty/oral-care",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568940915907564717584.jpg"
    }, {
      id: "fragrance",
      title: "Fragrance",
      href: "/category/health-beauty/fragrances",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568940f4be7b115982388.jpg"
    }, {
      id: "health-care",
      title: "Health Care",
      href: "/category/health-beauty/medicinal",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568942fc8374638552332.jpg"
    }, {
      id: "vitamins-supplements",
      title: "Makeup",
      href: "/category/health-beauty/health-beauty-vitamins-supplements",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568947164ee5352857555.jpg"
    }, {
      id: "mobility-accessibility",
      title: "Mobility & Accessibility",
      href: "/category/health-beauty/mobility-accessibility",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568945365825530562399.jpg"
    }],
    trendingProductsCategory: "beauty",
    description: "Our makeup range has everything you need to look picture perfect, from base products, to eye makeup, makeup brushes, lip products and nail care. You'll never pay full price for brands like Benefit, Loreal, MAC or Maybelline again. If it's discount skincare you're after, look no further. From anti-ageing to acne, we've got moisturisers, cleansers, toners and more, by brands including Garnier, Olay and Nivea. For runway-ready hair, we've got your shampoo, conditioner, hair mask and hair treatment needs sorted. Shop KMS, Redken and Goldwell for less. Guys, we haven't forgotten about you! Our range of beauty products includes a great selection for men, including fragrance, hair care, skincare and toiletries.",
    featuredEventIDs: [179875, 179876]
  },
  electronics: {
    pathPattern: /^\/category\/electronics-appliances\/browse\/?(\?.*)?$/,
    name: "Electronics",
    subtitle: "Stay connected with the latest audio, computers, appliances & more.",
    titleImageUrl: "https://s.catch.com.au/assets/0001/1050/656699898acd4759153895.webp",
    themeColor: "#C5EDEA",
    shopAllHref: "/category/electronics-appliances",
    subcategories: [{
      id: "appliances",
      title: "Appliances",
      href: "/category/electronics-appliances/appliances",
      imageUrl: "https://s.catch.com.au/assets/0001/1049/655abdb4cb478006089772.jpg"
    }, {
      id: "audio",
      title: "Audio",
      href: "/category/electronics-appliances/audio",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/656894f9e19d6105150781.jpg"
    }, {
      id: "cameras-drones",
      title: "Cameras & Drones",
      href: "/category/electronics-appliances/cameras-video-cameras",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568950530925128772869.jpg"
    }, {
      id: "car-vehicle-accessories",
      title: "Car & Vehicle Accessories",
      href: "/category/electronics-appliances/car-vehicle-electronics",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568950abb8b2229268884.jpg"
    }, {
      id: "computers",
      title: "Computers",
      href: "/category/electronics-appliances/computers",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/656895119b969746991798.jpg"
    }, {
      id: "cables-batteries",
      title: "Cables & Batteries",
      href: "/category/electronics-appliances/electronics-appliances-accessories",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/656895007b89f607781057.jpg"
    }, {
      id: "gaming",
      title: "Gaming",
      href: "/category/electronics-appliances/gaming",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568951bd3d33808372624.jpg"
    }, {
      id: "phones",
      title: "Phones",
      href: "/category/electronics-appliances/phones",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/65689527015bb515798473.jpg"
    }, {
      id: "security-home-tech",
      title: "Security & Home Tech",
      href: "/category/electronics-appliances/security-home-technology",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568952f302c1613064150.jpg"
    }, {
      id: "tv-video",
      title: "TV & Video",
      href: "/category/electronics-appliances/televisions-videos",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568953388968521981460.jpg"
    }, {
      id: "wearable-tech",
      title: "Wearable Tech",
      href: "/category/electronics-appliances/wearable-technology",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/656895396a078490796030.jpg"
    }, {
      id: "movies-tv-shows",
      title: "Movies & TV Shows",
      href: "/category/home-office-media/movies-tv-shows",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/65689522a30b5084006241.jpg"
    }],
    topBrands: [{
      name: "Nintendo",
      url: "/brand/nintendo",
      imageUrl: "https://s.catch.com.au/assets/0001/1051/659bea30ad399953985990.jpg"
    }, {
      name: "Apple",
      url: "/brand/apple",
      imageUrl: "https://s.catch.com.au/assets/0001/1051/659bea0928b3e012730340.jpg"
    }, {
      name: "Samsung",
      url: "/brand/samsung",
      imageUrl: "https://s.catch.com.au/assets/0001/1051/659bea351a2ab484166156.jpg"
    }, {
      name: "LG",
      url: "/brand/lg",
      imageUrl: "https://s.catch.com.au/assets/0001/1051/659bea2cb9f9f895025371.jpg"
    }, {
      name: "Beats",
      url: "/brand/beats",
      imageUrl: "https://s.catch.com.au/assets/0001/1051/659bea17d9508974275502.jpg"
    }, {
      name: "Garmin",
      url: "/brand/garmin",
      imageUrl: "https://s.catch.com.au/assets/0001/1051/659bea1c27d16850251782.jpg"
    }, {
      name: "Gopro",
      url: "/brand/gopro",
      imageUrl: "https://s.catch.com.au/assets/0001/1051/659bea27b536c236184840.jpg"
    }, {
      name: "Asus",
      url: "/brand/asus",
      imageUrl: "https://s.catch.com.au/assets/0001/1051/659bea1313748126658180.jpg"
    }],
    trendingProductsCategory: "electronics",
    description: "Whether you're moving into a new home or are looking to replace old homeware and kitchenware, you're at the right place. Here at Catch, we offer a generous selection of home and kitchen products at prices you won't find anywhere else. From brands such as Joseph Joseph, Salt & Pepper, Morrissey and so much more, there's so much to look at. With delivery straight to your doorstep, online shopping has never been easier! We've got cookware sets, bed linens, dinnerware and even small appliances at affordable prices. With our wide range of home and kitchen products, and easy delivery, why shop elsewhere? Get everything you need under one roof. Have a browse through our extensive range of home and kitchen products at Catch today!",
    featuredEventIDs: [179877, 179878]
  },
  homeLiving: {
    pathPattern: /^\/category\/home-living\/browse\/?(\?.*)?$/,
    name: "Home & Living",
    subtitle: "Make your house feel like home with cookware, decor bedding & more.",
    titleImageUrl: "https://s.catch.com.au/assets/0001/1050/656699812961f094542608.webp",
    themeColor: "#B6D7A2",
    shopAllHref: "/category/home-living",
    subcategories: [{
      id: "bathroom",
      title: "Bathroom",
      href: "/category/home-living/bathroom",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/65689323d4caa751331654.jpg"
    }, {
      id: "bedroom",
      title: "Bedroom",
      href: "/category/home-living/bedroom",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/65689329ad07c267051658.jpg"
    }, {
      id: "home-decor",
      title: "Home Decor",
      href: "/category/home-living/home-decor",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568934560eb9953076723.jpg"
    }, {
      id: "kitchen-dining",
      title: "Kitchen & Dining",
      href: "/category/home-living/kitchen-dining",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568934cda190460177153.jpg"
    }, {
      id: "laundry",
      title: "Laundry",
      href: "/category/home-living/home-living-laundry",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568935364838430239559.jpg"
    }, {
      id: "lighting",
      title: "Lighting",
      href: "/category/home-living/lighting",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568935f67241498596533.jpg"
    }, {
      id: "outdoor-living",
      title: "Outdoor Living",
      href: "/category/home-living/outdoor-living",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568936b88b41693555010.jpg"
    }, {
      id: "party-supplies-occasions",
      title: "Party Supplies & Occasions",
      href: "/category/home-living/party-supplies-occasions",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/65689370ccc59113605052.jpg"
    }, {
      id: "office-stationery",
      title: "Office & Stationery",
      href: "/category/home-office-media",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/65689366caf80297384161.jpg"
    }, {
      id: "books",
      title: "Books",
      href: "/category/home-office-media/home-office-media-books",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568932fbc905294293844.jpg"
    }, {
      id: "garden-tools-hardware",
      title: "Garden, Tools & Hardware",
      href: "/category/garden-tools-hardware",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/65689337a7523221870420.jpg"
    }, {
      id: "arts-crafts-sewing",
      title: "Arts, Crafts & Sewing",
      href: "/category/home-office-media/arts-crafts",
      imageUrl: "https://s.catch.com.au/assets/0001/1050/6568931b62b55526983428.jpg"
    }],
    topBrands: [{
      name: "Joseph & Joseph",
      url: "/brand/joseph-joseph",
      imageUrl: "https://s.catch.com.au/assets/0001/1051/659be99284fee725429693.jpg"
    }, {
      name: "Salt & Pepper",
      url: "/brand/salt-pepper",
      imageUrl: "https://s.catch.com.au/assets/0001/1051/659be99f5d486673745773.png"
    }, {
      name: "Maxwell Williams",
      url: "/brand/maxwell-williams",
      imageUrl: "https://s.catch.com.au/assets/0001/1051/659be999454d3110424086.jpg"
    }, {
      name: "Artiss",
      url: "/brand/artiss",
      imageUrl: "https://s.catch.com.au/assets/0001/1051/659be97a47d13143286288.jpg"
    }, {
      name: "Scanpan",
      url: "/brand/scanpan",
      imageUrl: "https://s.catch.com.au/assets/0001/1051/659be9b3ba52c683301406.jpg"
    }, {
      name: "Stanley Rogers",
      url: "/brand/stanley-rogers",
      imageUrl: "https://s.catch.com.au/assets/0001/1051/659be9b949fca728436665.jpg"
    }, {
      name: "Glasshouse Fragrances",
      url: "/brand/glasshouse-frangrances",
      imageUrl: "https://s.catch.com.au/assets/0001/1051/659be98d49a45744505109.jpg"
    }, {
      name: "bbox",
      url: "/brand/bbox",
      imageUrl: "https://s.catch.com.au/assets/0001/1051/659be9883ff2f738769382.jpg"
    }],
    trendingProductsCategory: "home-kitchen",
    description: "Whether you're moving into a new home or are looking to replace old homeware and kitchenware, you're at the right place. Here at Catch, we offer a generous selection of home and kitchen products at prices you won't find anywhere else. From brands such as Joseph Joseph, Salt & Pepper, Morrissey and so much more, there's so much to look at. With delivery straight to your doorstep, online shopping has never been easier! We've got cookware sets, bed linens, dinnerware and even small appliances at affordable prices. With our wide range of home and kitchen products, and easy delivery, why shop elsewhere? Get everything you need under one roof. Have a browse through our extensive range of home and kitchen products at Catch today!",
    featuredEventIDs: [179873, 179874]
  }
};