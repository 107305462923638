import { createAsyncThunk as originalCreateAsyncThunk } from "@reduxjs/toolkit";
import { SOURCE_TYPE_SEARCH } from "@catchoftheday/analytics";
import searchResultToProductItem from "../utilities/search/search.utilities.resultToProductItem";
export function createAsyncThunk(typePrefix, payloadCreator) {
  return originalCreateAsyncThunk(typePrefix, payloadCreator);
}
export function formatSearchResults(metadata, searchResults) {
  var pageLimit = metadata.limit,
      pageCurrent = metadata.page;
  var results = searchResults.filter(function (result) {
    return result.type === "product";
  });

  if (pageLimit !== undefined && pageCurrent !== undefined) {
    return results.map(function (result) {
      return searchResultToProductItem(result, {
        type: SOURCE_TYPE_SEARCH
      });
    });
  }

  return [];
}