import { trackStructEvent } from "@snowplow/browser-tracker";
export function emitFilterCollapse(_ref) {
  var action = _ref.action,
      filterName = _ref.filterName,
      searchQuery = _ref.searchQuery;
  trackStructEvent({
    category: "search",
    action: action,
    label: filterName,
    property: searchQuery
  });
}