export function dobToAgeRange(dateOfBirth) {
  if (!dateOfBirth) return null;
  var now = new Date();
  var dob = new Date(dateOfBirth);
  var age = now.getFullYear() - dob.getFullYear();
  var monthDiff = now.getMonth() - dob.getMonth();

  if (monthDiff < 0 || monthDiff === 0 && now.getDate() < dob.getDate()) {
    age--;
  }

  if (age < 18) {
    return "<18";
  } else if (age < 25) {
    return "18-24";
  } else if (age < 35) {
    return "25-34";
  } else if (age < 45) {
    return "35-44";
  } else if (age < 55) {
    return "45-54";
  } else if (age < 65) {
    return "55-64";
  } else if (age < 75) {
    return "65-74";
  } else if (age >= 75) {
    return ">74";
  }

  return null;
}