import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { createReducer } from "@reduxjs/toolkit";
import randomString from "@search-app/utilities/search/search.utilities.randomString";
import { searchFacetsVisiblity, searchFetch } from "../actions";
export var initialState = {
  isInitialSearch: true,
  facets: [],
  hasVisibleFacets: false,
  isClearAll: false,
  isLoading: false,
  error: null,
  results: [],
  session: {
    id: randomString(16),
    sequence: 0
  },
  metadata: {},
  suggestions: [],
  contexts: [],
  containsAdult: false,
  appliedFacets: []
};
export var reducer = createReducer(initialState, function (builder) {
  return builder.addCase(searchFetch.pending, function (state) {
    return _objectSpread(_objectSpread({}, state), {}, {
      isLoading: true,
      session: _objectSpread(_objectSpread({}, state.session), {}, {
        sequence: state.session.sequence + 1
      })
    });
  }).addCase(searchFetch.rejected, function (state) {
    return _objectSpread(_objectSpread({}, state), {}, {
      isClearAll: false,
      isLoading: false,
      error: "Failed to load search results"
    });
  }).addCase(searchFetch.fulfilled, function (state, action) {
    return _objectSpread(_objectSpread({}, state), {}, {
      facets: action.payload.facets,
      appliedFacets: action.payload.appliedFacets,
      isInitialSearch: false,
      isClearAll: false,
      isLoading: false,
      metadata: action.payload.metadata,
      results: action.payload.results,
      error: null
    });
  }).addCase(searchFacetsVisiblity, function (state) {
    return _objectSpread(_objectSpread({}, state), {}, {
      hasVisibleFacets: !state.hasVisibleFacets
    });
  });
});