import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/** Maps over a set of ListFacetItems depth first */
export function traverseMapListFacetItems(items, fn) {
  var parents = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  return items.map(function (item) {
    var mappedItem = fn(parents, item);
    return _objectSpread(_objectSpread({}, mappedItem), {}, {
      values: traverseMapListFacetItems(mappedItem.values || [], fn, parents.concat(mappedItem))
    });
  });
}
/**
 * Analagous to [].find for ListFacetItems
 */

export function traverseFindListFacetItems(items, fn) {
  var _foundItem;

  var foundItem;
  traverseListFacetItems(items, function (parents, item) {
    if (fn(item)) {
      foundItem = item;
    }
  });
  return (_foundItem = foundItem) !== null && _foundItem !== void 0 ? _foundItem : null;
}
/**
 * Traverses facet items depth first
 */

export function traverseListFacetItems(items, fn) {
  var parents = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  items.forEach(function (item) {
    fn(parents, item);
    traverseListFacetItems(item.values || [], fn, parents.concat(item));
  });
}