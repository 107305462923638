import { configureStore as toolkitConfigureStore } from "@reduxjs/toolkit";
import { initialState, reducer } from "./reducer";
export var configureStore = function configureStore() {
  var preloadedState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  return toolkitConfigureStore({
    preloadedState: preloadedState,
    reducer: reducer,
    // eslint-disable-next-line n/no-process-env
    devTools: false
  });
};